.container {
    height: 65px;
    width: '100%';
    text-align: center;
    position: fixed;
    bottom: 0;
    justify-content: space-evenly;
    background-color: white;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    box-shadow: 0 0 5px rgba(68, 67, 67, 0.2);
    padding-bottom: 3px;
  }


  .icon{
  font-size: 27px;
  margin-bottom: 6px;
  }

  .user-icon-container{
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

    .icon-container{

        display: block;
        }