@import url('https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600&display=swap');


html {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
  -webkit-tap-highlight-color: transparent;

}

*,*::before,*::after {
  box-sizing:inherit;
}

body{
  font-family: Arial, Helvetica, sans-serif;
  font-family: 'Belanosima', sans-serif;
    background-color: white;
    box-sizing: border-box !important;
    -ms-box-sizing: border-box !important;
    color: #333;
    font-size: 17px;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;

  }

  button{
    font-family: Arial, Helvetica, sans-serif;
    font-family: 'Belanosima', sans-serif;
    font-size: 17px;
    font-weight: bold;
    border: none;

  }

  .button:focus {
    outline: none;
  }
 

  a{
    text-decoration: none;
   color: #767676;
  }

  p{
    margin: 0;
  }

  .signout-container{
    width: 100%;
    height: 50px;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: right;
    padding-right: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;

  }

  .signout-text{
    font-weight: bold;
    margin-right: 15px;
    cursor: pointer;

  }


  .signout-icon{
    font-size: 20px;
  }


  
 


  .img-container{
    width: 100%;
    height: 170px;
    background-image: url("https://www.papierpeintpanoramique.fr/media/catalog/product/cache/871f459736130e239a3f5e6472128962/w/0/w09456_-_small.jpg");
    background-size: cover;
    margin-top: 50px;
  }

  .user-filter{
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.2);
     display: flex;
     padding-top: 35px;
      justify-content: center; 
      align-items: center;
  }

  .user-img{
  border-radius: 100px;
  width: 110px;
  height: 110px;
  }

  .user-name{
    margin-top: 17px;
    width: 100%;
    justify-content: center;
    display: flex;
    font-weight: bold;
    font-size: 30px;
          padding-left: 20px;
          padding-right: 20px;

    }

    .user-username{
      margin-top: 10px;
      width: 100%;
      justify-content: center;
      display: flex;
      color:#767676;
      }

      .user-data-container{
        width: 100%;
        justify-content: space-evenly;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
       
        font-weight: bold;
      }

      .user-data{
        display: flex;
      }

      .user-title{
        width: 100%;
        margin-top: 95px;
        justify-content: center;
        align-content: center;
        display: flex;
        font-weight: bold;

      }

      .user-empty{
        width: 100%;
        margin-top: 20px;
        justify-content: center;
        align-content: center;
        display: flex;
        color: #767676;
     

      }


      .user-succeed-container{

        padding: 0px  25px 90px 25px;
        
  

      }

      .user-succeed-filter{
        background: rgba(17, 17, 17, 0.2);
        padding: 15px;
 align-content: center;
        display: flex;
         align-items: center;
         border-radius: 16px;
      width: 100%;
      height: 100%;
      }


      .user-succeed{
        color: white;
        width: 100%;
        height: auto;
        border-radius: 16px;
        background-color: #333;
        margin-top: 20px;
       
       
       
        background-size: cover ;
        background-repeat: no-repeat;
        background-position: center;
      }








      .home-container{
        padding: 20px 20px 90px 20px;
        min-height: 100vh;
      }

      .home-logo{
       color: #FC6D3F;
       display: flex;
       justify-content: left;
       font-size: 25px;
       font-weight: bolder;
       align-items: center;
       margin-top: 20px;
      }

      .home-title{
       margin-top: 55px;
       margin-bottom: 20px;
       display: flex;
       justify-content: left;
       font-size: 32px;
       font-weight: bolder;
       line-height: 1.3;
       
      }


      .login-title{
        margin-top: 55px;
        margin-bottom: 20px;
        display: flex;
        justify-content: left;
        font-size: 30px;
        font-weight: bolder;
        line-height: 1.2;
        
       }



      .week-title{
        width: 100%;
        margin-top: 40px;
        margin-bottom: 10px;
        justify-content: left;
        align-content: center;
        display: flex;
        font-weight: bold;


      }

      .home-challenge{
        width: 100%;
        height: 210px;
        border-radius: 16px;
        margin-bottom: 20px;
        margin-top: 50px;
        color: white;
        font-weight: bold;
        background-color: #333;
        background-size: cover;
        opacity: 0.8;
      }

      .home-button-container{
        width: 100%;
        justify-content: center;
        display: flex;
        margin-top: 0px;
        margin-bottom: 30px;

      }

      .home-button{
        width: 100%;
        height: 40px;
        padding: 0px 30px;
        border-radius: 100px;
        text-decoration: none;
        background-color: #FC6D3F;
        color: white;
        font-weight: bold;
        align-content: center;
        border: none;
      }

      .home-button-about{
        width: 100%;
        height: 40px;
        padding: 0px 30px;
        border-radius: 100px;
        text-decoration: none;
        background-color: white;
        color: #333;
        font-weight: bold;
        align-content: center;
        border: none;
        border: 1px solid #767676;
        font-weight: normal;

      }

      .filter{
        width: 100%;
        height: 100%;
        background: rgba(17, 17, 17, 0.2);
        padding: 20px;
        border-radius: 16px;
      }


      .display-challenge{
        width: 100%;
        height: auto;
        background-color: #333;
      }

      .display-text{
        width: 100%;
        padding: 10px 15px;
        font-weight: bold;
      }

      .display-button{
        width: 100%;
        height: 45px;
        background-color: #FC6D3F;
        color: white;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        font-weight: bold;
      }

      .display-participate-container{
        width: 100%;
      
        background-color: #FC6D3F;
        color: white;
        width: 100%;
       
        justify-content: space-between;
        padding: 20px 30px;
        font-weight: bold;
      }

      .display-container{
        width: 100%;
        padding: 10px 15px ;
        padding-top: 0;
        margin-bottom: 25px ;

      
      }

      .display-title{
        width: 100%;
      display: flex;
      justify-content: center;
      font-weight: bold;  
      margin-top: 90px;
      padding:0 10px ;

      }

      .display-data{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        font-weight: bold;
      }


      .video-data-user{
        width: 100%;
      display: flex;
      align-content: center; 
      align-items: center;
      margin-bottom: 5px;
      }

      .video-image{
        width: 30px;
        height: 30px;
        border-radius: 100px;
        background-color: #333;
        margin-right: 5px;
      }

      .video video{
        width: 100%;
        height: 100%;
        min-height: 100vw;
        border-radius: 16px;
        background-color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;
      }

      .video {
        width: 100%;
        height: 100%;
        min-height: 100vw;
        border-radius: 16px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;
      }

      .video-header video{
        width: 100%;
        min-height: 100vw;
        background-color: #333;
      }

      .video-header {
        width: 100%;
        min-height: 100vw;
        background-color: #333;

      }

      .video-like{
        width: 100%;
      display: flex;
      align-content: center; 
      align-items: center;
      margin-top: 5px;
      color: #767676;
      justify-content: space-between;
      }


      .login-logo{
        color: #FC6D3F;
        display: flex;
        justify-content: left;
        font-size: 25px;
        font-weight: bolder;
        align-items: center;
        margin-bottom: 35px;
        margin-top: 7vh;
       }

       .login-button{
        width: 100%;
        height: 40px;
        padding: 0px 30px;
        border-radius: 100px;
        text-decoration: none;
        background-color: white;
        border: 1px solid #767676;
        color: #333;
        font-weight: normal;
        align-content: center;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding: 5px 10px;
        margin-top: 60px;
      

      }

      .loading{
        width: 100%;
        font-size:larger;
        color:#FC6D3F;
        margin-top: 20px;
        position: fixed;
        left: 0;
        right: 0;
        display: flex;
        margin-left: 2Opx;
        text-align: center;
        justify-content: center;
      }

      .notice-text{
        color: #767676;
        font-weight: normal;
      }



      .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .inputfile + label {
        
        color: white;
        display: inline-block;
        width: 100%;
        padding: 3px 0;
        border-bottom: 1px solid;  
        font-weight: 400; 
       }










